import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import VIBSeriesImage from "../../components/logicom-images/VibSeries.js"
import RSVIBSeriesImage from "../../components/logicom-images/RSVibSeries.js"

const Vibration = () => (
    <Layout>
      <SEO title="Vibration Test System"/>
      <Container>
        <Row><Col><h2>Vibration Test System</h2></Col></Row>
        <Row>
          <Col>
            <p class="lead">Vibration testing is performed for a variety of reasons</p>
            <ul>
              <li >Determine if a product can withstand the rigors of its intended use environment</li>
              <li >Ensure the final design will be suitable for shipment</li>
              <li >Weed out product defects and reduce warranty costs</li>
            </ul>
        </Col></Row>
        <br/>
        <Row className={'row-cols-1 row-cols-md-2'}>
          <Col>            
          <Row>
            <Col>
              <h4>Electrodynamic Vibration Systems</h4>
              <p>Today we continue the tradition of innovation with the development of the high performance DSX-Series of electrodynamic shakers. The DSX-Series shakers are available in five different capacities and features highly durable, lightweight magnesium armatures.</p>
              <a href="http://www.thermotron.com/product/vibration/electrodynamic-vibration-systems">Learn more about the Electrodynamic Vibration Systems</a>
            </Col>
          </Row>
          <Row>
            <Col>
              <VIBSeriesImage/>
            </Col>
          </Row>
          </Col>
          <Col>
            <Row><Col>
            <h4>Repetitive Shock Vibration</h4>
              <p>Thermotron has also created the new RSL table. This shaker features high energy content at frequencies below 2,000 Hz, provides better table uniformity for accurate test results in all quadrants and consistent energy axis to axis ensure similar stress in all axes.</p>
              <a href="http://www.thermotron.com/product/vibration/repetitive-shock-vibration-systems">Learn More About the Repetitive Shock Vibration</a>
            </Col></Row>
            <Row><Col>
              <RSVIBSeriesImage/>
            </Col></Row>
          </Col>
        </Row>
        <br/> 
        <Row><Col>
              
        </Col></Row>
        
        <Row><Col>
            <div>
              <h4>The Driving Force of Vibration Systems</h4>
              <p>Thermotron has been a global leader in high-performance vibration test equipment since 1977.</p>
              <ul>
                <li>First to introduce a low profile electrodynamic shaker to interface with environmental chambers.</li>
                <li>First to use switching (Class D) amplifiers to make our shakers more efficient and reliable than previous linear amplifiers.</li>
                <li>First to use microprocessor-based dynamic centering on our shakers.</li>
                <li>The only manufacturer in the industry that manufacturers all components needed to create a combined environmental test facility.</li>
                <li>Designer of the exclusive VCS-3200 vibration control system available for accurate sine, random, shock, resonant search and dwell, real data acquisition and playback (RDAP), random-on-random and sine-on-random capabilities.</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
  
  export default Vibration
