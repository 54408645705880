import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const RSVIBSeriesImage = ({props}) => {
    const data = useStaticQuery(graphql`
    query {
        placeholderImage: file(relativePath: { eq: "RS-36-final.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} {...props}/>
}

export default RSVIBSeriesImage